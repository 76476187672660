<template>
  <div>
    <div class="air__utils__heading"> <h5>Proceso Operativo</h5> </div>
    <my-breadcrumbs :routes="routes"/>
    <div class="row">
      <div class="col-lg-4 col-md-12">
        <div class="card">
          <router-link :to="{ name: 'production_orders'}">
            <div class="card-title">
              <h1 class="text-block mt-5 mb-4 text-center">
                <b-iconstack font-scale="1" variant="primary">
                  <b-icon stacked icon="card-checklist"/>
                </b-iconstack>
              </h1>
            </div>
            <div class="card-body text-center">
              <h6 class="text-block text-secondary">Órdenes de Producción</h6>
            </div>
          </router-link>
        </div>
      </div>
      <div class="col-lg-4 col-md-12">
        <div class="card">
          <router-link :to="{ name: 'finished_products'}">
            <div class="card-title">
              <h1 class="text-block mt-5 mb-4 text-center">
                <b-iconstack font-scale="1" variant="primary">
                  <b-icon stacked icon="check-circle"/>
                </b-iconstack>
              </h1>
            </div>
            <div class="card-body text-center">
              <h6 class="text-block text-secondary">Almacén de Producto Terminado</h6>
            </div>
          </router-link>
        </div>
      </div>
      <div class="col-lg-4 col-md-12">
        <div class="card">
          <router-link :to="{ name: 'empty_drums'}">
            <div class="card-title">
              <h1 class="text-block mt-5 mb-4 text-center">
                <b-iconstack font-scale="1" variant="primary">
                  <b-icon stacked icon="trash2"/>
                </b-iconstack>
              </h1>
            </div>
            <div class="card-body text-center">
              <h6 class="text-block text-secondary">Almacén de Tambos Vacios</h6>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'operationProcessMenu',
  data() {
    return {
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          name: 'operations',
          breadcrumbName: 'Operaciones',
        },
        {
          breadcrumbName: 'Proceso Operativo',
          aIcon: '',
        },
      ],
    }
  },
}
</script>
